let theme: string
let appName: string
let primaryCountries: string[]|undefined
let environment: string

switch (window.location.hostname) {
case 'myhuennebeck.com':
case 'localhost':
	theme = 'hbk'
	appName = 'myHuennebeck'
	primaryCountries = [
		'at',
		'de',
		'gb',
		'ie',
		'it',
		'nl',
		'pl',
		'ro',
	]
	break

case 'myhuennebeckportal.com':
	theme = 'hbk'
	appName = 'myHuennebeckPortal'
	primaryCountries = [
		'cl',
		'co',
		'cr',
		'do',
		'ec',
		'gt',
		'hn',
		'pa',
		'pr',
		'sv',
	]
	break

case 'mysgbgroup.com':
	theme = 'sgb'
	appName = 'mySGBgroup'
	primaryCountries = [
		'ae',
		'sa',
	]
	break

case 'myalumaportal.com':
	theme = 'aluma'
	appName = 'myAlumaPortal'
	primaryCountries = [
		'cr',
	]
	break

case 'mybeisportal.com':
	theme = 'beis'
	appName = 'myBeisPortal'
	primaryCountries = [
		'nl',
		'be',
	]
	break

case 'mysgbaluma.com':
	theme = 'sgbaluma'
	appName = 'mySGBAlumaPortal'
	primaryCountries = [
		'my',
		'sg',
	]
	break

case 'staging.myhuennebeck.de':
	theme = 'brand'
	appName = 'myBrandSafway'
	primaryCountries = [
		'at',
		'de',
		'gb',
		'ie',
		'it',
		'nl',
		'pl',
		'ro',
	]
	break

case 'mybrandaccess.com':
	theme = 'brandaccess'
	appName = 'myBrandAccess'
	primaryCountries = [
		'uk',
	]
	break

default:
	theme = import.meta.env.VITE_THEME
	appName = import.meta.env.VITE_APP_NAME
	primaryCountries = undefined
}

switch (window.location.hostname) {
case 'localhost':
case 'myhuennebeck.test':
case 'bauma.myhuennebeck.test':
case 'bauma.myhuennebeck.de':
	environment = 'development'
	break

case 'staging.myhuennebeck.de':
	environment = 'staging'
	break

default:
	environment = 'production'
}

export { primaryCountries, theme, appName, environment }
