<template>
	<nav
		ref="sidebar"
		id="sidebar-nav"
		:class="{
			collapsed: sidebarState.collapsed,
			'collapse-to': sidebarState.collapseTo,
			'collapse-from': sidebarState.collapseFrom,
		}"
	>
		<div class="flex-grow-1 w-100 overflow-auto d-flex flex-column justify-content-between">
			<ul class="sidebar-top flex-shrink-0">
				<li>
					<router-link to="/">
						<i class="bi bi-house"></i>
						<span>{{ $t('legacy.navStartpage') }}</span>
					</router-link>
				</li>
				<li v-if="authStore.hasPermission('pages.orders')">
					<a
						href="#"
						:class="{ expanded: expandedSection === 'order' }"
						class="nav-toggle"
						@click.prevent="toggle('order')"
					>
						<i class="bi bi-cart"></i>
						<span>{{ $t('nav.order') }}</span>
						<i
							class="bi expand-icon"
							:class="expandedSection === 'order' ? 'bi-chevron-up' : 'bi-chevron-down'"
						></i>
					</a>
					<transition
						name="nav-sub-menu"
						@enter="setSubMenuHeight('order')"
						@before-leave="setSubMenuHeight('order')"
					>
						<ul
							v-show="expandedSection === 'order'"
							class="nav-sub-menu nav-sub-menu-order"
						>
							<li>
								<router-link to="/orders/new/rent">
									<i class="bi bi-cart"></i>
									<span>{{ $t('orders.orderTypes.rent') }}</span>
								</router-link>
							</li>
							<li>
								<router-link to="/orders/new/sale">
									<i class="bi bi-cart"></i>
									<span>{{ $t('orders.orderTypes.sale') }}</span>
								</router-link>
							</li>
							<li v-if="!authStore.hasPermission('admin.orders.view')">
								<router-link to="/orders">
									<i class="bi bi-clock-history"></i>
									<span>{{ $t('history') }}</span>
								</router-link>
							</li>
							<!--
							<li>
								<a href="https://brandsafwaydirect.com" target="_blank">
									<i class="bi bi-box-arrow-up-right"></i>
									<span>{{ $t('nav.currentOffers') }}</span>
								</a>
							</li>
							-->
						</ul>
					</transition>
				</li>
				<li v-if="authStore.hasPermission('pages.projects')">
					<router-link :to="`/projects/${projectId || ''}`">
						<i class="bi bi-building"></i>
						<span>{{ $t('legacy.navProjects') }}</span>
					</router-link>
				</li>
				<li v-if="store.selected?.country === 'ro' && authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.order.view`)">
					<router-link :to="`/ro-order/${projectId || ''}`">
						<i class="bi bi-cart"></i>
						<span>{{ $t('legacy.orderPageTitle') }}</span>
					</router-link>
				</li>
				<li
					v-if="authStore.hasPermission('pages.assignedUsers')"
					:class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${store.selected?.customerNumber}.projects.${projectId}.admin.assignedUsers.view`) }"
				>
					<router-link
						:to="`/projects/${projectId || ''}/assigned-users`"
					>
						<i class="bi bi-person-check"></i>
						<span>{{ $t('legacy.navAssignedUsers') }}</span>
					</router-link>
				</li>
				<li
					v-if="authStore.hasPermission('pages.financials')"
					:class="{ disabled: projectId === null }"
				>
					<a
						href="#"
						:class="{ expanded: expandedSection === 'financials' }"
						class="nav-toggle"
						@click.prevent="toggle('financials')"
					>
						<i class="bi bi-calculator"></i>
						<span>{{ $t('legacy.navFinancials') }}</span>
						<i
							class="bi expand-icon"
							:class="expandedSection === 'financials' ? 'bi-chevron-up' : 'bi-chevron-down'"
						></i>
					</a>
					<transition
						name="nav-sub-menu"
						@enter="setSubMenuHeight('financials')"
						@before-leave="setSubMenuHeight('financials')"
					>
						<ul
							v-show="expandedSection === 'financials'"
							class="nav-sub-menu nav-sub-menu-financials"
						>
							<li :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.financials.invoices.view`) }">
								<router-link :to="`/projects/${projectId || ''}/financials/invoices`">
									<i class="bi bi-database-check"></i>
									<span>{{ $t('legacy.invoices') }}</span>
								</router-link>
							</li>
							<li :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.financials.balances.view`) }">
								<router-link :to="`/projects/${projectId || ''}/financials/balances`">
									<i class="bi bi-database-dash"></i>
									<span>{{ $t('legacy.openItems') }}</span>
								</router-link>
							</li>
						</ul>
					</transition>
				</li>
				<li
					v-if="authStore.hasPermission('pages.materials')"
					:class="{ disabled: projectId === null }"
				>
					<a
						href="#"
						:class="{ expanded: expandedSection === 'materials' }"
						class="nav-toggle"
						@click.prevent="toggle('materials')"
					>
						<i class="bi bi-boxes"></i>
						<span>{{ $t('legacy.navMaterials') }}</span>
						<i
							class="bi expand-icon"
							:class="expandedSection === 'materials' ? 'bi-chevron-up' : 'bi-chevron-down'"
						></i>
					</a>
					<transition
						name="nav-sub-menu"
						@enter="setSubMenuHeight('materials')"
						@before-leave="setSubMenuHeight('materials')"
					>
						<ul
							v-show="expandedSection === 'materials' && projectId !== null"
							class="nav-sub-menu nav-sub-menu-materials"
						>
							<li v-if="store.selected?.country !== 'cl'" :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.materials.shippings.view`) }">
								<router-link :to="`/projects/${projectId || ''}/materials/shippings`">
									<i class="bi bi-clipboard-check"></i>
									<span>{{ $t('legacy.deliveryNotes') }}</span>
								</router-link>
							</li>
							<li v-if="store.selected?.country === 'cl'" :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.materials.shippings.view`) }">
								<router-link :to="`/projects/${projectId || ''}/materials/dispatch-notes`">
									<i class="bi bi-clipboard-check"></i>
									<span>{{ $t('nav.materials.dispatchNotes') }}</span>
								</router-link>
							</li>
							<li :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.materials.reshippings.view`) }">
								<router-link :to="`/projects/${projectId || ''}/materials/reshippings`">
									<i class="bi fff">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="" viewBox="0 0 16 16">
											<path id="Path" fill-rule="evenodd" stroke="none" d="M 10.5 6 C 10.638071 6 10.75 6.111929 10.75 6.25 L 10.75 8.65 C 10.75 9.340356 10.190356 9.9 9.5 9.9 L 4.604 9.9 L 6.277 11.573 C 6.374754 11.670754 6.374754 11.829246 6.277 11.927 C 6.179246 12.024755 6.020754 12.024755 5.923 11.927 L 3.823 9.827 C 3.775979 9.780098 3.749553 9.716413 3.749553 9.65 C 3.749553 9.583586 3.775979 9.519902 3.823 9.473 L 5.823 7.473 C 5.920754 7.375246 6.079246 7.375246 6.177 7.473 C 6.274755 7.570754 6.274755 7.729246 6.177 7.827 L 4.604 9.35 L 9.5 9.35 C 9.914214 9.35 10.25 9.014214 10.25 8.6 L 10.25 6.25 C 10.25 6.111929 10.361929 6 10.5 6"/>
											<path id="path1" stroke="none" d="M 4 1.5 L 3 1.5 C 1.89543 1.5 1 2.395431 1 3.5 L 1 14 C 1 15.104569 1.89543 16 3 16 L 13 16 C 14.104569 16 15 15.104569 15 14 L 15 3.5 C 15 2.395431 14.104569 1.5 13 1.5 L 12 1.5 L 12 2.5 L 13 2.5 C 13.552285 2.5 14 2.947716 14 3.5 L 14 14 C 14 14.552285 13.552285 15 13 15 L 3 15 C 2.447715 15 2 14.552285 2 14 L 2 3.5 C 2 2.947716 2.447715 2.5 3 2.5 L 4 2.5 Z"/>
											<path id="path2" stroke="none" d="M 9.5 1 C 9.776142 1 10 1.223858 10 1.5 L 10 2.5 C 10 2.776142 9.776142 3 9.5 3 L 6.5 3 C 6.223857 3 6 2.776142 6 2.5 L 6 1.5 C 6 1.223858 6.223857 1 6.5 1 Z M 6.5 0 C 5.671573 0 5 0.671573 5 1.5 L 5 2.5 C 5 3.328427 5.671573 4 6.5 4 L 9.5 4 C 10.328427 4 11 3.328427 11 2.5 L 11 1.5 C 11 0.671573 10.328427 0 9.5 0 Z"/>
										</svg>
									</i>
									<span>{{ $t('legacy.backdeliveryNotes') }}</span>
								</router-link>
							</li>
							<li :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.materials.material.view`) }">
								<router-link :to="`/projects/${projectId || ''}/materials/rental-use`">
									<i class="bi bi-clipboard-data"></i>
									<span>{{ $t('legacy.rentalUse') }}</span>
								</router-link>
							</li>
							<li :class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.materials.rentalCard.view`) }">
								<router-link :to="`/projects/${projectId || ''}/materials/rental-card`">
									<i class="bi bi-card-text"></i>
									<span>{{ $t('materials.rentalCard.title') }}</span>
								</router-link>
							</li>
							<li v-if="store.selected?.country === 'de' && environment !== 'production'" :class="{ disabled: !authStore.hasPermission(`customers.${customerNumber}.projects.${projectId}.materials.rentalForecast.view`) }">
								<router-link :to="`/projects/${projectId || ''}/materials/rental-forecast`">
									<i class="bi bi-graph-up-arrow"></i>
									<span>{{ $t('materials.rentalForecast.title') }}</span>
								</router-link>
							</li>
						</ul>
					</transition>
				</li>
				<li
					v-if="authStore.hasPermission('pages.files')"
					:class="{ disabled: projectId === null || !authStore.hasPermission(`customers.${store.selected?.customerNumber}.projects.${projectId}.files.view`) }"
				>
					<router-link :to="`/projects/${projectId || ''}/files`">
						<i class="bi bi-cloud"></i>
						<span>{{ $t('nav.fileStorage') }}</span>
					</router-link>
				</li>
				<li v-if="authStore.hasPermission('pages.archive')">
					<router-link to="/archive">
						<i class="bi bi-archive"></i>
						<span>{{ $t('legacy.navArchive') }}</span>
					</router-link>
				</li>
			</ul>
			<div class="w-100 flex-shrink-0 mt-4">
				<ul class="sidebar-bottom" style="padding-bottom: 1rem;">
					<li v-if="authStore.hasPermission('pages.report')">
						<router-link to="/dashboard">
							<i class="bi bi-columns-gap"></i>
							<span>{{ $t('legacy.navDashboard') }}</span>
						</router-link>
					</li>
					<li>
						<a
							href="#"
							class="nav-toggle"
							:class="{ expanded: expandedSection === 'downloads' }"
							@click.prevent="toggle('downloads')"
						>
							<i class="bi bi-download"></i>
							<span>{{ $t('nav.downloadsAndVideos') }}</span>
							<i
								class="bi expand-icon"
								:class="expandedSection === 'downloads' ? 'bi-chevron-up' : 'bi-chevron-down'"
							></i>
						</a>
						<transition
							name="nav-sub-menu"
							@enter="setSubMenuHeight('downloads')"
							@before-leave="setSubMenuHeight('downloads')"
						>
							<ul
								v-show="expandedSection === 'downloads'"
								class="nav-sub-menu nav-sub-menu-downloads"
							>
								<li v-if="authStore.hasPermission('pages.downloads.documents')">
									<router-link to="/downloads/documents">
										<i class="bi bi-file-pdf"></i>
										<span>{{ $t('legacy.technicalDocuments') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.downloads.pricelists')">
									<router-link to="/downloads/pricelists">
										<i class="bi bi-tags"></i>
										<span>{{ $t('legacy.navPricelists') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.downloads.bim')">
									<router-link to="/downloads/software">
										<i class="bi bi-plugin"></i>
										<span>{{ $t('legacy.navBim') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.downloads.guides')">
									<a href="/guides-redirect" target="_blank">
										<i class="bi bi-info-circle"></i>
										<span>{{ $t('legacy.userManuals') }}</span>
									</a>
								</li>
								<li v-if="authStore.hasPermission('pages.videos')">
									<router-link to="/videos">
										<i class="bi bi-play-btn"></i>
										<span>{{ $t('legacy.videos') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.videos') && settings.language === 'de'">
									<router-link to="/videos/hcad">
										<i class="bi bi-play-btn"></i>
										<span>{{ $t('legacy.navHcad') }}</span>
									</router-link>
								</li>
							</ul>
						</transition>
					</li>
					<li v-if="authStore.hasPermission('pages.admin')">
						<a
							href="#"
							class="nav-toggle"
							:class="{ expanded: expandedSection === 'admin' }"
							@click.prevent="toggle('admin')"
						>
							<i class="bi bi-person-gear"></i>
							<span>{{ $t('legacy.navAdministration') }}</span>
							<i
								class="bi expand-icon"
								:class="expandedSection === 'admin' ? 'bi-chevron-up' : 'bi-chevron-down'"
							></i>
						</a>
						<transition
							name="nav-sub-menu"
							@enter="setSubMenuHeight('admin')"
							@before-leave="setSubMenuHeight('admin')"
						>
							<ul
								v-show="expandedSection === 'admin'"
								class="nav-sub-menu nav-sub-menu-admin"
							>
								<li v-if="authStore.hasPermission('admin.orders.view')">
									<router-link to="/orders">
										<i class="bi bi-cart-check-fill"></i>
										<span>{{ $t('orders.orderRequests') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.admin.users')">
									<router-link to="/admin/users">
										<i class="bi bi-people"></i>
										<span>{{ $t('nav.admin.users') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.admin.preferences')">
									<router-link to="/admin/system">
										<i class="bi bi-gear"></i>
										<span>{{ $t('nav.admin.system') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.admin.news')">
									<router-link to="/admin/news">
										<i class="bi bi-newspaper"></i>
										<span>{{ $t('nav.admin.news') }}</span>
									</router-link>
								</li>
								<li v-if="authStore.hasPermission('pages.admin.tickets')">
									<router-link to="/tickets">
										<i class="bi bi-envelope-fill"></i>
										<span>{{ $t('nav.admin.tickets') }}</span>
									</router-link>
								</li>
							</ul>
						</transition>
					</li>
				</ul>
			</div>
		</div>
		<div class="flex-grow-0 flex-shrink-0 w-100" style="box-shadow: 0 -1rem 1rem 0 #f7f7f7;">
			<hr class="w-100" />
			<ul class="collapse-btn">
				<li class="d-none d-sm-block">
					<a
						v-if="sidebarState.collapsed"
						href="#"
						@click.prevent="toggleSidebar()"
						class="btn btn-primary text-white show-sidebar-btn"
					>
						<i class="bi bi-chevron-double-right"></i>
					</a>
					<a
						v-else
						class="nav-toggle collapse-sidebar-btn"
						href="#"
						@click.prevent="toggleSidebar()"
					>
						<i class="bi bi-chevron-double-left"></i>
						<span>{{ $t('nav.collapseSidebar') }}</span>
					</a>
				</li>
				<li class="d-block d-sm-none">
					<a
						v-if="!sidebarState.collapsed"
						href="#"
						@click.prevent="toggleSidebar()"
					>
						<i class="d-block d-sm-none bi bi-chevron-double-up"></i>
						<i class="d-none d-sm-block bi bi-chevron-double-left"></i>
						<span>{{ $t('nav.hideNavigation') }}</span>
					</a>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script lang="ts" setup>
import {
	computed,
	ref,
	watch,
	nextTick,
	reactive,
	onMounted,
	onBeforeUnmount,
} from 'vue'
import { useRoute } from 'vue-router'

import { useProjectsStore } from '@/stores/projects'
import { useSettingsStore } from '@/stores/settings'
import { useAnalytics } from '@/services/AnalyticsService'
import { useAuthStore } from '@/stores/auth'
import { useEventBus } from '@/stores/bus'
import { environment } from '@/config/Theme'

const authStore = useAuthStore()
const settings = useSettingsStore()
const route = useRoute()
const store = useProjectsStore()
const { bus } = useEventBus()

const sidebar = ref<HTMLElement>()

const sidebarState = reactive({
	collapsed: false,
	collapseTo: false,
	collapseFrom: false,
})

const expandedSection = ref<string>()

const projectId = computed(() => store.selected?.id || null)
const customerNumber = computed(() => store.selected?.customerNumber || null)

const toggle = (section: string) => {
	if (expandedSection.value === section) {
		expandedSection.value = undefined
		return
	}

	expandedSection.value = section
}

watch(() => bus.value.get('toggleSidebar'), () => {
	toggleSidebar()
})

onMounted(() => {
	// expand parent section when route is set
	if (route.meta?.sidebarSection) {
		expandedSection.value = route.meta.sidebarSection as string
	} else {
		// restore expanded section from local storage
		const restoredExpandedSection = window.localStorage.getItem('sidebarExpandedSection')

		if (restoredExpandedSection) {
			expandedSection.value = restoredExpandedSection
		}
	}

	// set min height of sidebar and listen on scroll/resize events
	setSidebarMinHeight()
	window.addEventListener('scroll', setSidebarMinHeight)
	window.addEventListener('resize', setSidebarMinHeight)

	// wait until content is rendered and set min height again
	window.addEventListener('load', () => {
		setSidebarMinHeight()
	})
})

onBeforeUnmount(() => {
	window.removeEventListener('scroll', setSidebarMinHeight)
	window.removeEventListener('resize', setSidebarMinHeight)
})

watch(() => expandedSection.value, () => {
	if (expandedSection.value) {
		window.localStorage.setItem('sidebarExpandedSection', expandedSection.value)
	} else {
		window.localStorage.removeItem('sidebarExpandedSection')
	}
})

watch(() => route.name, () => {
	// expanded parent section when route changes
	if (route.meta.sidebarSection) {
		expandedSection.value = route.meta.sidebarSection as string
	}

	if (window.innerWidth < 576) {
		sidebarState.collapsed = true
	}
})

const setSidebarMinHeight = () => {
	if (!sidebar.value || window.innerWidth < 576) {
		return
	}

	const sidebarTop = sidebar.value.getBoundingClientRect().top || 0
	sidebar.value.style.height = `calc(100vh - ${sidebarTop < 0 ? 0 : sidebarTop}px)`
}

const setSubMenuHeight = (name: string) => {
	// event handler called before sidebar sub nav transition is executed
	const subMenu: HTMLUListElement | null = document.querySelector(`.nav-sub-menu-${name}`)

	if (!subMenu) {
		return
	}

	let height = 2

	for (const child of subMenu.children) {
		height += child.clientHeight + 1.5
	}

	subMenu.style.height = `${height}px`

	setTimeout(() => {
		subMenu.style.height = 'auto'
	}, 500)
}

const toggleSidebar = () => {
	if (sidebarState.collapsed) {
		sidebarState.collapseFrom = true

		nextTick(() => {
			sidebarState.collapsed = false
		})

		setTimeout(() => {
			sidebarState.collapseFrom = false
		}, 350)

		return
	}

	useAnalytics().track('Sidebar collapsed')

	sidebarState.collapsed = true
	sidebarState.collapseTo = true

	setTimeout(() => {
		sidebarState.collapseTo = false
	}, 350)
}
</script>

<style lang="scss">
@import '@/assets/styles/variables';

#sidebar-nav {
	flex-basis: 25%;
	min-width: 250px;
	max-width: 300px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	justify-content: space-between;
	align-items: flex-start;
	position: sticky;
	top: 0;
	transition-duration: 0.35s;
	transition-property: width, flex-basis, min-width, max-width;
	// height: 100vh;

	@media (max-height: 720px) {
		padding: 0.75rem;
	}

	@media (max-width: 576px) {
		position: relative;
		height: auto !important;
		min-height: none !important;
		max-width: none;
		flex-basis: auto;
		align-self: stretch;
	}
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	bottom: 0;
	// 	z-index: 1000;
	// 	background: #fff;
	// 	backdrop-filter: blur(1rem);
	// }

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		width: 100%;

		li {
			margin: 0.125rem 0;
			transition: opacity 0.5s;

			@media (max-height: 720px) {
				margin: 0.1rem 0;
			}

			&.disabled {
				opacity: 0.3;
				pointer-events: none;
			}

			ul {
				margin-left: 1.5rem;
				width: calc(100% - 1.5rem);
				transition: all 0.35s;
			}

			a {
				text-decoration: none;
				padding: 0.5rem 1rem;
				display: flex;
				justify-content: space-between;
				border-radius: 0.5rem;
				font-weight: bold;

				@media (max-height: 720px) {
					padding: 0.35rem 1rem;
				}

				span {
					flex-grow: 1;
					transition: 0.25s all;
				}

				.bi {
					display: inline-block;
					margin-right: 0.5rem;
					transition: all 0.35s;
				}
			}

			a:not(.btn) {
				color: $gray;

				&.router-link-active,
				&:hover {
					background-color: #fff;
					color: $primary;
				}
			}
		}
	}

	&.collapsed {
		flex-basis: 1%;
		min-width: 80px;
		/*min-width: 95px;*/
		max-width: 150px;
		flex-shrink: 0;
		flex-grow: 0;

		@media (max-height: 720px) {
			// min-width: 55px;
		}

		@media (max-width: 576px) {
			max-width: none;
			width: auto;
			display: none;

			hr {
				display: none;
			}

			ul:not(.collapse-btn), div.w-100 {
				display: none;
			}
		}

		ul:not(.collapse-btn) {
			li {
				a {
					&.expanded {
						background-color: #fff;
						color: $primary;
						border-radius: 0.5rem 0.5rem 0 0;
					}

					> * {
						flex-shrink: 0;
					}

					span {
						display: none;
						opacity: 0;
					}
				}

				ul {
					margin-left: 0;
					width: 100%;
					background-color: #fff;
					border-radius: 0 0 0.5rem 0.5rem;
				}

				.bi {
					margin-right: 0;

					&.expand-icon {
						// display: none;
						font-size: 0.75rem;
						align-self: center;
						margin-left: 0.25rem;
					}
				}
			}
		}
	}

	// temporary class while collapsing
	&.collapse-to {
		ul {
			li {
				a {
					span {
						display: none;
						opacity: 0;
					}
				}
			}
		}
	}

	// temporary class while expanding
	&.collapse-from {
		ul {
			li {
				a {
					white-space: nowrap;

					span {
						display: inline !important;
						opacity: 0;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
</style>
