import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
// @ts-ignore
import { MotionPlugin } from '@vueuse/motion'

import en from '@/locales/en.json'
import de from '@/locales/de.json'
import es from '@/locales/es.json'
import it from '@/locales/it.json'
import nl from '@/locales/nl.json'
import pl from '@/locales/pl.json'
import ro from '@/locales/ro.json'
import cs from '@/locales/cs.json'

import App from './App.vue'
import router from './router'

import '@/assets/styles/main.scss'
import 'bootstrap'
import 'masonry-layout'

import setupInterceptors from '@/services/setupInterceptors'

setupInterceptors()

const app = createApp(App)

if (!import.meta.env.DEV) {
	Sentry.init({
		app,
		dsn: 'https://c85565420f854d54995b9e7dd6aab227@o1116559.ingest.sentry.io/4504837739642880',
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracePropagationTargets: ['localhost', 'staging.myhuennebeck.de', /^\//],
			}),
		],
		environment: import.meta.env.VITE_ENV || 'production',
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.1,
		ignoreErrors: [
			/^.*Failed to fetch dynamically imported module.*$/i,
			/^.*error loading dynamically imported module*$/i,
			/^.*Importing a module script failed.*$/i,
			/^.*Unable to preload CSS for.*$/i,
		],
	})
}

app.use(createPinia())
app.use(router)
app.use(
	createI18n({
		legacy: false,
		locale: navigator.language.split('-')[0] || 'en',
		fallbackLocale: 'en',
		messages: {
			en,
			de,
			es,
			it,
			nl,
			pl,
			ro,
			cs,
		},
		globalInjection: true,
	}),
)
app.use(MotionPlugin)

app.mount('#app')
